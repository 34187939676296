.content-info {
  hr {
    margin-top: 23px;
    margin-bottom: 0;
    background-color: $light-grey;
  }

  p {
    @extend .text-center;
    &.copy {
      font-size: 12px;
      a {
        color: $black;
      }
    }
  }

  .footer-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: $screen-sm-min) {
      flex-wrap: nowrap;
    }
  }

  figure {
    position: relative;
    @extend .add-big-bottom;
    figcaption {
      @include transition();
      @extend .text-center;
      @extend .text-uppercase;
      background-color: $light-grey;
      padding: 15px 0;
      color: $black;
      h2 {
        $font-primary: 'SFCompactDisplay', sans-serif;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 14px;
        margin: 0;
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    &:hover {
      figcaption {
        background-color: $brand-primary;
        color: $white;
      }
    }
  }

  .social {
    display: flex;
    justify-content: center;
    text-align: center;
    a {
      display: flex;
      max-width: 30px;
      svg {
        @include transition(fill);
        vertical-align: middle;
        height: 30px;
      }
      &.facebook {
        fill: $facebook;
        &:hover {
          fill: darken($facebook, 20%);
        }
      }

      &.google {
        fill: $google;
        &:hover {
          fill: darken($google, 20%);
        }
      }

      &:hover {

      }
    }
    a:first-child {
      margin-right: 15px;
    }
  }
}

.onvard {
  display: inline-block;
  @include transition();
  margin-left: 8px;
  fill: $black;

  svg {
    vertical-align: middle;
    .o,
    .v {
      opacity: 0.9;
    }

    .circle {
    }
    width: 17px;
    height: 17px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.7);
  }
}
