.banner {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  margin: 0;
  border: none;
  background-color: $white;
  .container {
    &:before,
    &:after {
      content: none;
    }
    @media (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .navbar-header {
    padding-left: 15px;
    @media (min-width: $screen-sm-min) {
      padding: 0;
    }
    display: flex;
    justify-content: space-between;
    &:before,
    &:after {
      content: none;
    }
  }

  .navbar-toggle {
    .icon-bar {
      background-color: $brand-primary;
    }

    &:hover {
      .icon-bar {
        background-color: darken($brand-primary, 20%);
      }
    }
  }

  .navbar-collapse {
    @media (min-width: $screen-sm-min) {
      padding: 0;
    }
  }

  .brand {
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 15px;
    svg {
      vertical-align: middle;
      height: 30px;
      @media (min-width: $screen-sm-min) {
        height: 50px;
      }
    }
  }

  .nav {
    padding-bottom: 15px;
    @media (min-width: $screen-sm-min) {
      padding: 0;
    }
    li {
      @media (min-width: $screen-sm-min) {
        display: inline-block;
      }
      a {
        @include transition();
        font-family: $font-primary;
        @extend .text-center;
        color: $black;
        font-size: 16px;
        @media (min-width: $screen-sm-min) {
          padding: 10px;
        }

        &:hover,
        &:focus {
          background-color: $brand-primary;
          color: $white;
          @media (min-width: $screen-sm-min) {
            background-color: transparent;
            color: $brand-primary;
          }
        }
      }
      &.active {
        a {
          background-color: $brand-primary;
          color: $white;
          @media (min-width: $screen-sm-min) {
            background-color: transparent;
            color: $brand-primary;
          }
        }
      }
    }
  }
}
