.btn {
  background-color: $green;
  border-radius: 4px;
  font-size: 18px;
  color: $white;
  fill: $white;
  border: none;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg, span {
    vertical-align: middle;
  }
  svg {
    margin-right: 10px;
    height: 24px;
  }
  &:active,
  &:focus,
  &:hover {
    background-color: darken($green, 20%);
    color: $white;
  }
  &:focus {
    outline: none;
  }
}

.call {
  display: flex;
  justify-content: center;

  .btn {
    max-width: 200px;
    @include transition();
    transform: translateY(-50%);
  }
}
