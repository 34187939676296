.home-header {
  @media (min-width: $screen-sm-min) {
    position: relative;
    padding-bottom: 37%;
    padding-top: 25px;
    height: 0;
  }

  .flow-wrapper {
    display: none;
    @media (min-width: $screen-sm-min) {
      display: block;
    }
  }
}
.page-header {
  height: 200px;
  @media (min-width: $screen-sm-min) {
    height: 350px;
  }
  background-size: cover;
  background-position: center center;
  border: none;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  .title {
    position: relative;
    h1 {
      line-height: 1;
      background-color: $brand-primary;
      color: $white;
      padding: 10px 15px;
      display: inline-block;
      @extend .text-uppercase;
    }
  }
}
.home-header,
.page-header {
  position: relative;
  .background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
  }
}

.content-block {
  margin-bottom: -1px;
  padding: 15px;
  background-image: linear-gradient(to bottom, $light-grey, rgba($white, 0.7));

  @media (min-width: $screen-sm-min) {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  h1 {
    @extend .text-center;
    margin-top: 0;
    @extend .add-big-bottom;
  }

  .home & h1 {
    @extend .add-top;
  }

  img {
    @extend .img-responsive;
  }

  p:last-child {
    margin-bottom: 0;
  }

}
.form-container {
  border-top: 1px solid $light-grey;
}

.main {
  .nav-tabs {
    display: flex;
    justify-content: center;
    border: none;
    @extend .add-big-bottom;
    >li {
      width: 20%;
      > a {
        background-color: transparent;
        color: $black;
        border: none;
        @extend .text-center;
        padding: 0;
        font-size: 11px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        fill: $black;
        svg {
          @include transition();
          vertical-align: middle;
          border-radius: 100%;
          background-color: transparent;
          height: 50px;
          @media (min-width: $screen-sm-min) {
            height: 100px;
          }
          path:first-child {
            @include transition();
            fill: $black;
          }
        }
        span {
          flex-grow: 1;
          display: block;
          padding: 10px;
        }
        &:hover {
          background-color: transparent;
          fill: $white;
          svg {
            background-color: $brand-primary;
            path:first-child {
              fill: $brand-primary;
            }
          }
        }
        &:focus {
          background-color: transparent;
          border: none;
        }
      }
      &.active {
        > a {
          background-color: transparent;
          fill: $white;
          &,
          &:hover,
          &:focus {
            border: none;
          }
        }
        svg {
          background-color: $brand-primary;
          path:first-child {
            fill: $brand-primary;
          }
        }
      }
    }
  }
  .tab-pane {
    &.fade {
       @include transition(opacity);
    }
  }
  .tab-content {
    @extend .add-big-bottom;
  }
}
