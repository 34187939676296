// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}


.form-contact {
    @extend .add-big-top;
    @extend .add-big-bottom;
    .form-footer {
        margin-bottom: 0;
        @media (min-width: $screen-sm-min) {
            display: flex;
        }
    }

    .alert-container {
        flex: 1;
        margin-right: 15px;
    }
    .alert {
        @extend .add-top;
        @extend .add-bottom;
        @media (min-width: $screen-sm-min) {
            margin: 0;
        }
        padding: 11px 15px;
        opacity: 0;
        @include transition(opacity);
        &.toggle {
          opacity: 1;
        }
    }

    .submit {
        @include transition();
        margin-right: 0;
        background-color: $green;
        color: $white;
        &:hover {
          background-color: darken($green, 20%);
        }
    }
}

.form-control {
    font-weight: 300;
    @include transition();
    &:focus {
        box-shadow: none;
    }
}

.form-group {

    @extend .add-big-bottom;
    &.has-feedback {

        .form-control-feedback {
            top: 42px;
            transform: translateY(-50%);
            height: 26px;
            right: 0;
            opacity: 0;
            @include transition();

            &.success {
                fill: $green;
            }
            &.error {
                fill: $red;
            }
        }
    }

    &.has-success {
        .form-control {
            border-color: $green;
        }
        .success {
            opacity: 1;
        }
    }

    &.has-error {
        .form-control {
            border-color: $red;
        }
        .error {
            opacity: 1;
        }
    }

    &.has-success,
    &.has-error {
        .form-control {
            &:focus {
                box-shadow: none;
            }
        }
    }

}

textarea{
  &.form-control {
    height: 125px;
  }
  resize:vertical;
}
