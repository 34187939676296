@include font-face('SFCompactDisplay', '#{$font-path}SFCompactDisplay-Thin', 100, normal);
@include font-face('SFCompactDisplay', '#{$font-path}SFCompactDisplay-Regular', normal, normal);
@include font-face('SFCompactDisplay', '#{$font-path}SFCompactDisplay-Medium', bold, normal);
body {
  overflow-x: hidden;
  background-color: $white;
  font-family: $font-secondary;
  @media (min-width: $screen-sm-min) {
    font-size: 16px;
  }
  .wrap {
    padding-top: 60px;
    @media (min-width: $screen-sm-min) {
      padding-top: 80px;
    }
  }
}
h1,
.h1 {
  font-size: 30px;
  font-family: $font-primary;
  font-weight: 100;
}
.add-top {
  margin-top: 15px;
}
.add-bottom {
  margin-bottom: 15px;
}
.add-big-top {
  margin-top: 30px;
}
.add-big-bottom {
  margin-bottom: 30px;
}
.container {
  .navbar-collapse {
    margin-right: 0;
  }
}
.container-small,
.container-medium {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
.container-small {
  max-width: 610px;
}
.container-medium {
  max-width: 960px;
  .row {
    display: flex;
    justify-content: center;
    &:before,
    &:after {
      content: none;
    }
  }
}
.preload {
  opacity: 0;
  @include transition(opacity);
  &.loaded {
    opacity: 1;
  }
}
/* Edge */
.edgeLoad-EDGE-221436396 {
  visibility: hidden;
}
#text {
  margin: 50px auto;
  width: 500px;
}
.hotspot {
  color: #900;
  padding-bottom: 1px;
  border-bottom: 1px dotted #900;
  cursor: pointer;
}
#tt {
  font: 11px/1.5 Verdana, Arial, Helvetica, sans-serif;
  position: absolute;
  display: block;
  background: url("../images/tooltip/tt_left.gif") top left no-repeat;
  #tttop {
    display: block;
    height: 5px;
    margin-left: 5px;
    background: url("../images/tooltip/tt_top.gif") top right no-repeat;
    overflow: hidden;
  }
  #ttcont {
    display: block;
    padding: 2px 12px 3px 7px;
    margin-left: 5px;
    background: #666;
    color: #FFF;
  }
  #ttbot {
    display: block;
    height: 5px;
    margin-left: 5px;
    background: url("../images/tooltip/tt_bottom.gif") top right no-repeat;
    overflow: hidden;
  }
}
